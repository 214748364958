import { put, call, all, takeLatest, takeEvery } from 'redux-saga/effects'
import { ActionTypes } from '../../actions/profile.actions'
import { get, patch, post } from '../../api'
import { calculateUserAge } from '../../../utilities/'

const basePath = '/api/user'

export function* getAllProfileData(action) {
    try {
        const { authToken } = action.payload.user
        const response = yield call(get, `${basePath}/me`, '', {
            Authorization: authToken,
        })
        const data = yield response.json()
        const profileInfo = data.content
        const userAge = calculateUserAge(profileInfo.birthday)


        if (response.status >= 200 && response.status < 300) {
            yield put({
                type: ActionTypes.GET_ALL_PROFILE_DATA_SUCCESS,
                userAge: userAge,
                profileData: profileInfo,
            })
        } else {
            throw data
        }
    } catch (error) {
        yield put({
            type: ActionTypes.GET_ALL_PROFILE_DATA_FAILURE,
            errorMessage: error.content,
        })
    }
}


export function* saveProfileData(action) {
    const { newData, user } = action.payload
    const { authToken } = action.payload.user



    try {
        const response = yield call(
            patch,
            '/api/user/save',
            {
                userInfo: newData,
                authUserInfo: user
            },
            {
                Authorization: authToken
            }
        )

        const data = yield response.json()

        if (response.status >= 200 && response.status < 300) {
            yield put({ type: ActionTypes.SAVE_PROFILE_DATA_SUCCESS, res: data })
        }

    } catch (error) {
        yield put({
            type: ActionTypes.SAVE_PROFILE_DATA_FAILURE,
            error: error.content
        })
    }
}

function* profile() {
    yield all([
        takeLatest(ActionTypes.GET_ALL_PROFILE_DATA, getAllProfileData),
        takeEvery(ActionTypes.SAVE_PROFILE_DATA, saveProfileData)])

}

export default profile